import AppFooter from "@crema/core/AppLayout/components/AppFooter";
import AppHeader from "@crema/core/AppLayout/Default/AppHeader";
import { Layout, Segmented, Space } from "antd";
import { useState } from "react";

export const Services = () => {
  const [language, setLanguage] = useState("Tiếng Việt");
  return (
    <>
      <Layout className="app-layout-main">
        <AppHeader onToggleSidebar={() => ""} />
      </Layout>

      <div
        className="container"
        style={{
          minHeight: "70vh",
          width: "max-content",
          height: "100%",
          marginTop: "2em",
        }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Segmented
            onChange={(e) => setLanguage(e as string)}
            options={["Tiếng Việt", "English"]}
          />
        </Space>
        <div style={{ fontSize: "15px" }} hidden={language != "Tiếng Việt"}>
          <h2>DỊCH VỤ</h2>

          <ul style={{ listStyle: "none" }}>
            <li>- GỬI HÀNG & GỬI QUÀ VỀ VIỆT NAM (GIAO TẬN NHÀ) </li>
            <li>- NHẬN HÀNG ORDER ONLINE</li>
            <li>- ORDER HỘ HÀNG MỸ & CANADA </li>
            <li>- BÁN SĨ LẺ CÁC LOẠI SỮA, DẦU NÓNG, TPCN, … </li>
            <li>- NHẬN LÀM PASSPORT & VISA VIỆT NAM</li>
          </ul>
        </div>

        <div style={{ fontSize: "15px" }} hidden={language != "English"}>
          <h2>SERVICES</h2>
          <ul style={{ listStyle: "none" }}>
            <li>
              - COURIER & FREIGHT SHIPPING TO VIETNAM (DOOR TO DOOR DELIVERY)
            </li>
            <li>- RECEIVING ONLINE ORDERS</li>
            <li>- ASSISTING IN ORDERING US & CANADIAN GOODS</li>
            <li>
              - WHOLESALE AND RETAIL SELLING OF MILK POWDER, MEDICATED OIL,
              SUPPLEMENTS, ...
            </li>
            <li>- PROVIDING PASSPORT & VISA TO VIETNAM</li>
          </ul>
        </div>
      </div>

      <AppFooter />
    </>
  );
};
