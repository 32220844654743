import { Modal } from "antd";
import { GoogleMaps } from "components/Map/GoogleMaps";
import { useState } from "react";
// import "../styles/addressModal.scss";

interface CoordAddress {
  address: string;
  lat: number;
  lng: number;
}

export const AddressModal = ({
  open,
  onClose,
  onSubmitOk,
}: {
  open: boolean;
  onClose: () => void;
  onSubmitOk: (address: string, lat: number, long: number) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [addressInfo, setAddressInfo] = useState<CoordAddress>();

  const handleSubmitForm = async () => {
    if (addressInfo) {
      onSubmitOk(addressInfo.address, addressInfo.lat, addressInfo.lng);
      onClose();
    }
  };

  return (
    <Modal
      className="address-modal"
      onCancel={onClose}
      open={open}
      title={"Chọn địa chỉ"}
      confirmLoading={loading}
      onOk={handleSubmitForm}
      width={600}
    >
      <GoogleMaps onPlaceSelected={(address) => setAddressInfo(address)} />
    </Modal>
  );
};
