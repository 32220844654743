import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { OrderForm } from "types/order";
import { getTitle } from "utils";

export const CheckTrackingInputSection = ({
  title,
  loading,
  onTracking,
}: {
  title: string;
  loading: boolean;
  onTracking: (trackingId: string) => void;
}) => {
  const [form] = useForm<Partial<OrderForm>>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = getTitle(title);
  }, [title]);

  useEffect(() => {
    if (searchParams.get("trackingId")) {
      handleAutoTracking(searchParams.get("trackingId") as string);
    }
  }, [searchParams.get("trackingId")]);

  const handleAutoTracking = (trackingParam?: string) => {
    const trackingId = form.getFieldValue("trackingId");
    if (trackingParam && !trackingId) {
      form.setFieldValue("trackingId", trackingParam);

      handleCheck();
    }
  };

  const handleCheck = () => {
    const trackingId = form.getFieldValue("trackingId");
    if (trackingId) {
      onTracking(trackingId.trim());
    } else {
      message.warning("Vui lòng nhập Tracking ID");
    }
  };

  return (
    <>
      <div
        className="b-container banner"
        style={{
          backgroundImage: "url('/images/bg-1802.png')",
        }}
      >
        <Row className="">
          <Col
            span={24}
            // style={{
            //   width: "100%",
            //   display: "flex",
            //   flexDirection: "column",
            //   marginBottom: 0,
            // }}
          >
            {/* <h1 className="banner--title">
              TRA CỨU ĐƠN HÀNG <br />
              <span className="banner--subtitle">Track your shipment</span>
            </h1> */}
            <Form
              layout="horizontal"
              form={form}
              style={{ width: "100%", display: "flex" }}
              className={"tracking-form"}
            >
              <FormItem
                style={{ flex: "1" }}
                name="trackingId"
                className="form-item"
              >
                <Input
                  style={{ width: `300px` }}
                  size="large"
                  placeholder={"Tìm đơn hàng theo số TRK#"}
                  onPressEnter={handleCheck}
                />
              </FormItem>
              <FormItem className="form-item">
                <Button
                  block
                  onClick={handleCheck}
                  type="primary"
                  size="large"
                  loading={loading}
                  icon={<SearchOutlined />}
                >
                  Tra cứu / Track
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};
export { CheckTrackingInputSection as default };
